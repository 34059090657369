import './image-slider.scss';
import Swiper from 'swiper';
import { A11y, Navigation, Keyboard } from 'swiper/modules';

class ImageSlider {
    constructor (element) {
        this.$slider = element;
    }

    initialize () {
        this.initSlider();
    }

    initSlider () {
        this.$slider = new Swiper(this.$slider, {
            modules: [A11y, Navigation, Keyboard],
            a11y: true,
            lazyPreloadPrevNext: true,
            keyboard: true,
            slidesPerView: 'auto',
            slidesPerGroupAuto: true,
            spaceBetween: 10,
            speed: 500,
            navigation: {
                nextEl: '.image-slider__next',
                prevEl: '.image-slider__prev'
            },
            breakpoints: {
                1024: {
                    spaceBetween: 45
                }
            }
        });
    }
}

export { ImageSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-imageslider="root"]');
        if ($$roots.length > 0) {
            $$roots.forEach($root => {
                const $rootAPI = new ImageSlider($root);
                $rootAPI.initialize();
            });
        }
    }
});
